<ng-container *ngIf='isUserAccessValidationPassed && !isStandalonePage'>
  <sim-portal-toolbar
    (click)="$event.stopPropagation()"
    (toggleDrawer)="drawer.toggle()"
    [isStoreOpen]='showEcwidStore'
    [isHeadset]="isHandset"
    [switchHeader$]="switchHeader$">
  </sim-portal-toolbar>
  <mat-sidenav-container *ngIf="!isOptionalViewActive" (click)="closeSidenav()">
    <mat-sidenav
      #drawer
      disableClose
      fixedInViewport
      class="mat-sidenav-wrapper"
      [class.sidenav-closed]="isSideBarClosed"
      [class.sidenav-opened]="!isSideBarClosed || expandMode"
      (click)="$event.stopPropagation()"
      [attr.role]="isHandset ? 'dialog' : 'navigation'"
      [mode]="isHandset ? 'over' : 'side'"
      [opened]="isHandset === false">
      <div class="wrapper-sidenav">
        <lib-app-sidenav
          [isCollapseMenu]="true"
          [expandMode]="expandMode"
          [isSideBarClosed]="isSideBarClosed"
          (toggleSideMenu)="toggleSideNav($event)"
          (toggleExpandMode)="toggleExpandMode()">
        </lib-app-sidenav>
      </div>
    </mat-sidenav>

    <mat-sidenav-content
      #sidenavContent
      class="mat-sidenav-content-wrapper"

      [class.sidenav-content-closed]="isSideBarClosed"
      [class.sidenav-content-opened]="!isSideBarClosed">
      <div class="main-flex">
        <div class="main main--with-margins" *ngIf='routingConfigService.envName$ | async as envName'>
          <div [ngStyle]="!showEcwidStore ? {'display': 'none'} : {'display': 'block'}">
            <sim-order *ngIf='envName !== envNameEnum.TELIT'></sim-order>
          </div>
          <div *ngIf='!showEcwidStore'>
            <router-outlet></router-outlet>
          </div>
        </div>
        <div>
          <lib-footer></lib-footer>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>

<ng-template #floDocumentViewer>
  <sim-document-viewer-tools></sim-document-viewer-tools>
</ng-template>
<router-outlet
  name="optional"
  (activate)="toggleOptionalView(true)"
  (deactivate)="toggleOptionalView(false)"
></router-outlet>

<router-outlet name="standalone"></router-outlet>
