export enum SimRoute {
  CONNECTIVITY_RATES = 'connectivity-rates',
  RATES_AND_FEES = 'rates',
  // RATES_AND_FEES = 'rates-and-fees',
  DASHBOARD = 'dashboard',
  DEVICE_MANAGEMENT = 'device-management',
  DWH = 'dwh',
  PLANS = 'plans',
  RA = 'ra',
  RATER = 'RATER',
  REPORTS = 'reports',
  RESOURCES = 'resources',
  SIM_MANAGEMENT = 'sim-management',
  SIMS_PROVISIONING = 'sims-provisioning',
  SETTINGS = 'settings',
  BILLING_AND_INVOICES = 'billing-and-invoices',
  SUPPORT = 'support',
  SUB_ACCOUNTS = 'sub-accounts',
  NOTIFICATIONS = 'notifications',
  RULES_AND_NOTIFICATIONS = 'rules-and-notifications',
  ABOUT = 'about',
  AVAILABILITY_ZONES = 'availability-zones',
  PLAN_AVAILABILITY_ZONES = 'plan-availability-zones',
  BATCH_OPERATIONS = 'batch-operations',
  BATCH_RECORDS = 'batch-records',
  FLO_DOCUMENT_VIEWER = 'flo-document-viewer',
  SERVICE_PROVIDERS = 'service-providers',
  INVOICES = 'invoices',
  ORDER_SIM = 'order-sim',
  ORDER = 'account',
  PRODUCTS = 'category',
  SHOP_CART = 'cart',
  REGISTRATION = 'registration',
}
