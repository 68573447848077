import {Injectable} from '@angular/core';
import {EcwidConnectionModel} from '../../order/order.model';
import {CurrentAccountService} from 'projects/shared/src/lib/services/current-account/current-account.service';
import * as CryptoJS from 'crypto-js';
import {map, switchMap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {EShopSettingsQuery} from '../../order/gql/e-shop-settings.query';

@Injectable({
  providedIn: 'root',
})
export class EcwidInitializationService {
  ecwidInitData: EcwidConnectionModel = {
    accountID: '',
    accountName: '',
    userName: '',
    appClientId: '',
    clientSecret: '',
    storeId: '',
  };
  ecwidPageNavigationByRouting$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private readonly currentAccountService: CurrentAccountService,
    private readonly eShopSettingsQuery: EShopSettingsQuery,
  ) {}

  getProfileInfo(): Observable<EcwidConnectionModel> {
    return this.eShopSettingsQuery.fetch().pipe(switchMap(({data}) => {
      return this.currentAccountService.get()
        .pipe(
          map(account => {
            this.ecwidInitData.accountID = account.uuId;
            this.ecwidInitData.userName = account.username;
            this.ecwidInitData.accountName = account.name;
            this.ecwidInitData.appClientId = data.eShopSettings?.appClientId;
            this.ecwidInitData.clientSecret = data.eShopSettings?.clientSecret;
            this.ecwidInitData.storeId = data.eShopSettings?.storeId;

            return this.ecwidInitData;
          }),
        );
    }));
  }

  SSOConnection(profileData: EcwidConnectionModel): void {
    const clientSecret = profileData.clientSecret;
    const profile = JSON.stringify({
      appClientId: profileData.appClientId,
      userId: profileData.accountID,
      profile: {
        email: profileData.userName,
        billingPerson: {
          name: profileData.userName,
          companyName : profileData.accountName,
          street : '',
          city: '',
          countryCode: '',
          postalCode: '',
          stateOrProvinceCode: '',
        },
      },
    }) ;
    const message: string = btoa(profile);
    const timestamp: number = Math.round( Date.now() / 1000);
    const hmac = CryptoJS.HmacSHA1(message + ' ' + timestamp, clientSecret).toString(CryptoJS.enc.Hex);

    window['ecwid_sso_profile'] = message + ' ' + hmac + ' ' + timestamp;
  }

}
