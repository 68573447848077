import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

export interface EShopSettingType {
  eShopSettings: {
    accountId: string;
    appClientId: string;
    clientSecret: string;
    storeId: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class EShopSettingsQuery extends Query<EShopSettingType> {
  document = gql`
    query {
      eShopSettings {
        accountId
        appClientId
        clientSecret
        storeId
      }
    }
  `;
}
