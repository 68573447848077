import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})

export class TranslationSettingsService {
  private readonly key = 'lang';

  constructor(
    private readonly translate: TranslateService,
  ) {}

  getSavedLang(): string {
    return JSON.parse(localStorage.getItem(this.key)) || this.translate.defaultLang;
  }

  setAndSaveLang(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem(this.key, JSON.stringify(lang));
  }

  setAndSaveLangInner(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem(this.key, JSON.stringify(lang));
    // TODO Ecwid shop workaround
    location.reload()
  }

  setSavedLang(): void {
    const lang = this.getSavedLang();
    const defaultLang = this.translate.getDefaultLang();

    if (lang && lang !== defaultLang) {
      this.translate.use(lang).subscribe({error: err => {
        localStorage.removeItem(this.key);
      },
      });
    }
  }

}
