<div *ngIf="isImpersonated" class="impersonated">
  <p>
    <mat-icon svgIcon="flo-v-background"></mat-icon>
    <span [innerHTML]="'logged-in-on-behalf' | translate: {email: userData?.username}"></span>
  </p>
  <p>|</p>
  <p>
    <mat-icon svgIcon="flo-clock"></mat-icon>
    <span [innerHTML]="'session-timeout' | translate: {time: impersonationSessionTimeout | msToTime}"></span>
  </p>
</div>

<mat-toolbar class="main-toolbar" fxLayout="row" fxLayoutAlign="start center">
  <lib-loading-spinner mode="bar"></lib-loading-spinner>
  <mat-toolbar-row
    fxLayout="row"
    fxLayoutAlign="space-between center">

    <lib-app-flo-live-logo class="flo-toolbar-logo"></lib-app-flo-live-logo>

    <ng-container #header></ng-container>
  </mat-toolbar-row>
</mat-toolbar>

<mat-autocomplete
  #auto="matAutocomplete"
  [panelWidth]='"370px"'
  (optionSelected)="navigateToSim($event)">
  <mat-option *ngIf="isPending">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </mat-option>

  <mat-optgroup
    class="toolbar-search-optgroup"
    *ngFor="let searchItem of searchResult | keyvalue" [label]="searchItem.key | translate">
    <mat-option
      *ngFor="let sim of searchItem.value"
      [value]="sim.subscriberId"
      [innerHTML]="sim[searchItem.key] | boldText: searchControl.value: 'search-string-bold-color'">
    </mat-option>
  </mat-optgroup>
</mat-autocomplete>

<mat-menu #userMenu class='user-menu-list'>
  <button
    *ngIf="aboutLink"
    (click)="openDocumentViewerWindow(aboutLink)"
    class="toolbar-menu-item"
    mat-menu-item>
    <mat-icon svgIcon="flo-about-info"></mat-icon>{{'about' | translate}}
  </button>
  <button
    [disabled]="!termsOfUseLink"
    (click)="openDocumentViewerWindow(termsOfUseLink)"
    class="toolbar-menu-item"
    mat-menu-item>
    <mat-icon svgIcon="flo-terms-of-use"></mat-icon>{{'terms-of-use' | translate}}
  </button>

  <button [matMenuTriggerFor]="langsMenu" mat-menu-item>
    <mat-icon svgIcon="flo-language"></mat-icon>{{'languages' | translate}}
  </button>

  <a
    *ngIf="supportCenterLink"
    [href]="supportCenterLink"
    target="_blank"
    class="toolbar-menu-item"
    mat-menu-item>
    <mat-icon svgIcon="flo-support"></mat-icon>{{'support-open-ticket' | translate}}
  </a>

  <button *ngIf="impersonationAllowed && !isImpersonated" (click)="impersonate()" class="toolbar-menu-item" mat-menu-item>
    <mat-icon svgIcon="flo-users"></mat-icon>
    {{'login-on-behalf' | translate}}
  </button>
  <button *ngIf="isImpersonated" (click)="unImpersonate()" class="toolbar-menu-item" mat-menu-item>
    <mat-icon svgIcon="flo-users"></mat-icon>
    {{'logout-on-behalf' | translate}}
  </button>

  <button (click)="logout()"
          class="toolbar-menu-item" mat-menu-item>
    <mat-icon svgIcon="flo-log-out"></mat-icon>{{'log-out' | translate}}
  </button>
<!--    <button [matMenuTriggerFor]="themeMenu" mat-menu-item>-->
<!--      <i class="mdi mdi-palette-advanced"></i>{{'theme-menu' | translate}}-->
<!--    </button>-->
</mat-menu>
<mat-menu #langsMenu>
  <button (click)="changeLang(lang)"
          mat-menu-item
          *ngFor="let lang of langs"
          class="toolbar-menu-item">
    {{lang | translate}}
  </button>
</mat-menu>
<mat-menu #themeMenu>
  <button (click)="changeTheme(theme.name)"
          mat-menu-item
          *ngFor="let theme of themes">
    {{theme.name | translate}}
  </button>
</mat-menu>

<ng-template #defaultHeader>
  <div class="user-menu">
    <div class="mat-body sims-search">
      <button (click)="toggleDrawer.emit()"
              *ngIf="isHeadset"
              class="drawer-button"
              mat-icon-button
              type="button">
      </button>

      <div *ngIf='envName !== envNameEnum.TELIT' class="search__container" matAutocompleteOrigin #origin="matAutocompleteOrigin">
        <div
          class='search__field fade-in'>
          <span matPrefix>
            <mat-icon
              class='default-table-header__button_default search__search-icon'
              svgIcon='flo-search'>
            </mat-icon>
          </span>
          <input
            id="searchSimInput"
            #searchInput
            class="search__input"
            matInput
            [matAutocomplete]="auto"
            [matAutocompleteConnectedTo]="origin"
            [formControl]="searchControl"
            [placeholder]="('search-sim' | translate)">

          <button
            *ngIf='searchControl.value'
            mat-icon-button
            disableRipple='true'
            matSuffix
            (click)="clearSearchField()">
            <mat-icon svgIcon="close-chip" class="search__close-icon toolbar-search-close-icon"></mat-icon>
          </button>
        </div>
      </div>
    </div>

    <mess-messaging *ngIf='envName !== envNameEnum.TELIT'></mess-messaging>

    <div id='ecwid-button-wrapper'>
      <div *bdDomElementValidator='ordersRole' data-icon='BAG' class="ec-cart-widget"></div>
      <div id='ecwid-cart-button-container'></div>
    </div>

    <button
      *ngIf="userManualFileName"
      (click)="onDownloadUserManualClick()"
      mat-icon-button>
      <mat-icon svgIcon="flo-help"></mat-icon>
    </button>
      <a
        mat-icon-button
        *ngIf="userManualUrl"
        [href]="userManualUrl"
        target="_blank">
        <mat-icon svgIcon="flo-help"></mat-icon>
      </a>
    <span [matMenuTriggerFor]="userMenu"
          class="user-info"
          matRipple>

        <img *ngIf="avatarPath; else initialsTemplate;"
             alt="Avatar"
             class="user-info__avatar"
             [src]="avatarPath | ftpApiPrefix">
        <ng-template #initialsTemplate>
          <div class="user-info__initials">{{userData?.name | initials}}</div>
        </ng-template>
        <div *ngIf="!isHeadset" class="user-info__name">
          <p class="mat-subtitle-1 user-name">{{userData?.username | nullOrUndefinedHandler: '--'}}</p>
          <p
            class="mat-caption user-company">{{userData?.description | nullOrUndefinedHandler: ('unknown' | translate)}}</p>
        </div>

        <mat-icon class="user-info__arrow" svgIcon="flo-menu-down"></mat-icon>
      </span>
  </div>
</ng-template>
