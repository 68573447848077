import {Component, Renderer2, AfterViewInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {EcwidInitializationService} from '../_shared/services/ecwid-initialization.service';

import {EcwidConnectionModel} from './order.model';

@Component({
  selector: 'sim-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements AfterViewInit {
  profileData: EcwidConnectionModel;

  constructor(
    private readonly renderer2: Renderer2,
    private readonly ecwidService: EcwidInitializationService,
    private readonly translate: TranslateService,
  ) {}

  ngAfterViewInit(): void {
    this.ecwidService.getProfileInfo().subscribe(res => {
      if (res.storeId) {
        this.profileData = res;
        this.ecwidService.SSOConnection(res);
        this.integrateEcwidEmbeddedCode(this.profileData.storeId);
      }
    });
  }

  private integrateEcwidEmbeddedCode(storeId: string): void {
    // TODO Resolve hash fragment issue in ecwid store
    this.ecwidRouteRemoveExtraHash();
    const currentLang = this.translate.currentLang ?? this.translate.defaultLang;
    const script = this.renderer2.createElement('script');
    const date = new Date().toISOString().split('T')[0];

    script.setAttribute('type', 'text/javascript');
    script.setAttribute('id', 'ecwid-starter-script');
    script.setAttribute('charset', 'utf-8');
    script.setAttribute('data-cfasync', 'false');
    script.setAttribute(
      'src',
      `https://app.ecwid.com/script.js?${storeId}&lang=${currentLang}&data_platform=code&data_date=${date}`,
    );

    script.onload = this.injectEcwidProductBrowser(storeId);
    this.renderer2.appendChild(document.body, script);
  }

  private injectEcwidProductBrowser(storeId: string): () => void {
    return (): void => {
      (window as any).xProductBrowser('categoriesPerRow=3', 'views=grid(20,3)', 'categoryView=grid', 'searchView=list', `id=my-store-${storeId}`);
      this.ecwidService.ecwidPageNavigationByRouting$.subscribe(ecwidNavRoute => {
        window['Ecwid'].OnPageLoaded.add(() => {
          window['Ecwid'].openPage(ecwidNavRoute);
        });
      });

      window['Ecwid'].OnPageLoaded.add(page => {
        if (page.type === 'CART') {
          document.getElementById('ec-cart-email-input')?.setAttribute('disabled', '');
        }
      });
    };
  }

  private ecwidRouteRemoveExtraHash(): void {
    const script = this.renderer2.createElement('script');

    script.text = 'window.ec = window.ec || {};' +
      'window.ec.config = window.ec.config || {};' +
      'window.ec.config.storefrontUrls = window.ec.config.storefrontUrls || {};' +
      'window.ec.config.storefrontUrls.cleanUrls = true;' +
      'window.ec.config.storefrontUrls.queryBasedCleanUrls = true;';
    this.renderer2.appendChild(document.head, script);
  }
}
