import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, take} from 'rxjs/operators';

export const fetchAndSetFavicon = (domain: string, iconType: string, httpClient: HttpClient, document: HTMLDocument): void => {
  getFaviconFromFtp(domain, iconType, httpClient).subscribe((exist: boolean) => {
    setFavicon(domain, iconType, document, exist);
  });
};

const getFaviconFromFtp = (domain: string, iconType: string, httpClient: HttpClient): Observable<any> =>
  httpClient.get(`file-storage/is-file-exists/login/favicon/${domain}.${iconType}`)
    .pipe(
      take(1),
      catchError(() => of(false)),
    );

const setFavicon = (domain: string, iconType: string, document: HTMLDocument, exist: boolean): void => {
  let link: HTMLLinkElement = document.querySelector('link[rel~="icon"]');

  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = exist ?
    `file-storage/download-cors/login/favicon/${domain}.${iconType}` :
    './assets/favicon/favicon-96x96.png';
};

