<div
  class="flo-select-search flo-icon-color"
  [class.thin-select]='thinSelect'
  [class.mat-select-search-inner-multiple]="matSelect.multiple">
  <mat-icon matPrefix svgIcon="flo-search" class="search-icon"></mat-icon>
  <input
    #searchSelectInput
    id="bd-input"
    name="bd-input"
    class="mat-subtitle-1 mb-0 input"
    placeholder="{{placeholder | translate}}"
    [formControl]="searchControl"
    (keydown)="$event.stopPropagation()"/>
  <button
    class="small-icon-button"
    [ngClass]="{'hidden': !searchControl.value}"
    mat-icon-button
    matSuffix
    (click)="reset(true)">
    <mat-icon class="cancel-icon" svgIcon="flo-close"></mat-icon>
  </button>
</div>

<div
  *ngIf="searchControl.value && showedOptionsCount === 0 && !toCreateNew"
   class="no-entries-found mat-secondary-text-color">
  {{noEntriesFound | translate}}
</div>

<div *ngIf="toCreateNew && showCreateNew"
     class="create-new"
     (click)="createNewItem(searchControl.value)">
  <div class="plus">+</div>
  <div>{{'create-new-' + createNewName | translate}} <strong>{{searchControl.value}}</strong></div>
</div>

<ng-content></ng-content>
