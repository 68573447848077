import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

interface LangsConfigResponse {
  langs: {
    config: string[];
    envName: string;
  };
}

@Injectable({providedIn: 'root'})
export class LangsQuery extends Query<LangsConfigResponse>{
  document = gql`query { langs: simLangs }`;
}
