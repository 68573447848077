import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

import {SimModel} from 'projects/shared/src/lib/models/sim.model';

export interface ToolbarSearchSimQueryResponse {
  searchSims: {
    list: SimModel[];
  };
}

@Injectable()
export class ToolbarSearchSimQuery extends Query<ToolbarSearchSimQueryResponse> {
  document = gql`
    query($params: SimsPageInput!) {
      searchSims(paginatedRequest: $params) {
        list: content {
          eid
          subscriberId
          lastActiveImsi
          lastActiveICCID
          alias
          lastActiveMsisdn
          identifiers {
            iccid
            imsiMsisdnPairs {
              imsi
              msisdn
            }
          }
        }
      }
    }
  `;
}
