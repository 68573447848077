import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bd-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSearchComponent implements OnInit {
  @Input() noEntriesFound = 'global.other.nothing-found';
  @Input() placeholder = 'search';
  @Input() searchField: string;
  @Input() translatedValueSearch: 'kebabCase' | 'camelCase' | 'disabled' = 'disabled';
  @Input() thinSelect: boolean = false;
  @Input() toCreateNew: boolean;
  @Input() createNewName: string;
  @Output() createNew: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('searchSelectInput', {read: ElementRef}) searchSelectInput: ElementRef;
  @ContentChildren(MatOption, {read: ElementRef}) optionRefs: QueryList<ElementRef>;
  @ContentChildren(MatOption) options: QueryList<MatOption>;

  readonly searchControl = new FormControl();
  showedOptionsCount = 0;
  showCreateNew = false;
  private searchFilter: (opt: MatOption, searchText: string) => boolean;

  constructor(@Inject(MatSelect) public matSelect: MatSelect) {}

  ngOnInit(): void {
    this.initControlListeners();
    this.initSearchFilter();
  }

  focus(): void {
    const panel = this.matSelect.panel.nativeElement;
    const scrollTop = panel.scrollTop;

    this.searchSelectInput.nativeElement.focus();

    panel.scrollTop = scrollTop;
  }

  reset(focus?: boolean): void {
    this.searchControl.setValue('');
    if (focus) {
      this.focus();
    }
  }

  search(input: string): void {
    const options = this.options.toArray();
    const optionRefs = this.optionRefs.toArray();
    let searchText: string;

    if (this.translatedValueSearch === 'disabled') {
      searchText = input.toLowerCase();
    } else if (this.translatedValueSearch === 'kebabCase') {
      searchText = input.replace(' ', '-').toLowerCase();
    } else if (this.translatedValueSearch === 'camelCase') {
      searchText = input.replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`);
    }

    this.showedOptionsCount = 0;

    options.forEach((opt, index) => {
      const showOption = this.searchFilter(opt, searchText);

      if (showOption) {
        this.showedOptionsCount++;
      }
      optionRefs[index].nativeElement.style.display = showOption ? null : 'none';
    });

    this.showCreateNew = searchText && !options.find(option => option.value === searchText);
  }

  createNewItem(value: string): void {
    this.createNew.emit(value);
    this.showCreateNew = false;
  }

  private initControlListeners(): void {
    this.matSelect.openedChange
      .pipe(untilDestroyed(this))
      .subscribe(opened => {
        if (opened) {
          this.focus();
        } else {
          this.reset();
        }
      });

    this.searchControl.valueChanges.pipe(
      untilDestroyed(this),
    ).subscribe((value: string) => {
      this.search(value);
    });
  }

  private initSearchFilter(): void {
    if (this.searchField) {
      this.searchFilter = (opt: MatOption, searchText: string) =>
        this.searchField?.split('.')
          .reduce((obj, key) => obj?.[key], opt.value)?.toLowerCase().includes(searchText);
    } else {
      this.searchFilter = (opt: MatOption, searchText: string) =>
        opt.viewValue?.toLowerCase().includes(searchText);
    }
  }
}
