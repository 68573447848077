import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DocumentNode} from 'graphql';
import gql from 'graphql-tag';
import {LogicService} from 'projects/shared/src/lib/abstract-section-v2/providers/logic.service';
import {ExpirationStrategyTypeEnum} from 'projects/shared/src/lib/enums/expiration-strategy-type.enum';
import {NameIdModel} from 'projects/shared/src/lib/models/name-id.model';

import {PlanModes, PlanTypes} from '../plan.model';

import {PoolPlanDto} from './pool-plans-dtos/pool-plan.dto';

export interface PoolPlanRawType {
  name: string;
  accountId: string;
  accountName: string;
  type: string;
  renewalInterval: string;
  poolPlan: {
    type: string;
  };
  cost: {
    value: number;
    currency: string;
  };
  services: {
    sms: {
      included: boolean;
      value: number;
    };
    data: {
      included: boolean;
      value: number;
      currency: string;
    };
  };
  limits: {
    included: boolean;
    limited: boolean;
    data: {
      value: number;
      currency: string;
    };
    sms: {
      value: number;
    };
  };
  offerRenewalStrategy: string;
}

@Injectable()
export class PoolPlanLogic implements LogicService<PoolPlanDto> {
  static readonly poolPlansFragment = gql`
      fragment poolPlansFragment on OfferingPlanType {
          assignedSims
          billingPlanId
          customerAccountId
          dataAllowance
          dataAllowanceUnit
          dataLimit
          dataLimitUnit
          designatedCustomerAccountId
          designatedCustomerAccountName
          externalParameters {
              name
              id
              instanceId
              value
          }
          expirationPeriodType
          expirationPeriodUnits
          expirationStrategyType
          effectiveDateTo
          moneyAllowance
          moneyAllowanceCurrency
          name
          nestedOfferAllowanceDataBalanceId
          nestedOfferAllowanceSmsBalanceId
          nestedProductOffersIds
          linkedProductOfferings {
              id
              name
              priceValue
              priceCurrency
              planType
              recurringType
              renewalStrategy
              offerLinkType
          }
          planType
          poolType
          priceCurrency
          priceValue
          productOfferingId
          recurringType
          recurringTypeStrategy
          recurringTypeUnits
          recurringTypeValue
          renewalStrategy
          smsAllowance
          smsLimit
          totalDataAllowance
          totalSmsAllowance
          currentDataUsage
          currentSmsUsage
          zoneSets {
              id
              name
          }
          allowedImsiProviders {
            id
            providerAlias
          }

          ## ResolveField
          zoneSetsFull {
              id
              name
              zones {
                  id
                  name
                  networks {
                      name
                  }
                  country {
                      name
                      iso
                  }
              }
          }
      }`;

  primaryKey = 'id';

  queryList: DocumentNode = gql`
      query($createdByMe: Boolean) {
          poolPlans(createdByMe: $createdByMe) {
              ...poolPlansFragment
          }
      }
      ${PoolPlanLogic.poolPlansFragment}
  `;

  queryOne: DocumentNode = gql`
      query($planId: String, $createdByMe: Boolean) {
          poolPlanById(planId: $planId, createdByMe: $createdByMe) {
              ...poolPlansFragment
          }
      }
      ${PoolPlanLogic.poolPlansFragment}
  `;

  querySubscriberOfferForPoolPlan: DocumentNode = gql`
      query($accountId: ID!, $planId: ID!) {
          getSubscriberOfferForPoolPlan(accountId: $accountId, planId: $planId){
              subscriber{
                  id
              }
              balanceAggregators{
                  subscriberBalance{
                      balance
                      currency
                      id
                  }
                  value
              }
              productOffering{
                  customFields
                  offerTerm{
                      allowance
                  }
              }
              nestedOffers{
                  balanceAggregators{
                      subscriberBalance{
                          balance
                          currency
                          id
                      }
                      value
                  }
                  productOffering{
                      customFields
                      offerTerm{
                          allowance
                      }
                  }
              }
          }
      }
  `;

  querySubscribers: DocumentNode = gql`
      query ($pageOptions: SimsPageInput!) {
          sims: pageFloPortalSims(paginatedRequest: $pageOptions) {
              list: content {
                  displayedCustomerAccountName
                  subscriberId
                  parentSubscriberId
                  childSubscriberId
                  lastActiveICCID
                  lastActiveImsi
                  status

                  fromParentSubscriberOffer {
                    smsBarUsage
                    dataBarUsage
                    dataBarUnit
                  }
                  toChildSubscriberOffer {
                    smsBarUsage
                    dataBarUsage
                    dataBarUnit
                    productOfferingId
                  }
                  hasChild
              }
              totalItems: totalElements
          }
      }`;

  constructor(
    private readonly fb: FormBuilder,
    private readonly translate: TranslateService,
  ) {}

  form(plan?: PoolPlanDto, poolPlanType?: PlanModes): FormGroup {
    return this.fb.group({
      name: this.fb.control(plan?.name ? this.translate.instant('copy-of', {string: plan.name}) : null),
      accountId: this.fb.control(poolPlanType === PlanModes.sellPlan ? plan?.designatedCustomerAccountId ?? null : null),
      accountName: this.fb.control(plan?.designatedCustomerAccountName ?? null),
      type: PlanTypes.POOL,
      renewalInterval: this.fb.group({
        period: this.fb.control(plan?.recurringType ?? null),
        type: this.fb.control(plan?.recurringTypeStrategy ?? null),
        day: this.fb.control(plan?.recurringTypeValue ?? null),
      }),
      expirationStrategy: this.fb.group({
        expirationType: this.fb.control(plan?.expirationStrategyType ?? ExpirationStrategyTypeEnum.NO_EXPIRATION),
        date: this.fb.control(plan?.effectiveDateTo ?? null),
        duration: this.fb.control(plan?.expirationPeriodUnits ?? null),
        unit: this.fb.control(plan?.expirationPeriodType ?? null),
      }),
      poolPlan: this.fb.group({
        type: this.fb.control(plan?.poolType ?? null),
      }),
      cost: this.fb.group({
        value: this.fb.control(plan?.priceValue ?? null),
        currency: this.fb.control(plan?.priceCurrency ?? null),
      }),
      services: this.fb.group({
        sms: this.fb.group({
          included: this.fb.control(!!plan?.planDescription?.sms || !plan?.name),
          value: this.fb.control(parseInt(plan?.planDescription?.sms?.fieldValue?.toString().split(' ')?.[0], 10) ?? null),
        }),
        data: this.fb.group({
          included: this.fb.control(!!plan?.planDescription?.data || !plan?.name),
          value: this.fb.control(parseInt(plan?.planDescription?.data?.fieldValue?.toString().split(' ')?.[0], 10) ?? null),
          currency: this.fb.control(plan?.planDescription?.data?.fieldValue?.toString().split(' ')?.[1] || null),
        }),
      }),
      limits: this.fb.group({
        included: this.fb.control(null),
        data: this.fb.group({
          value: this.fb.control(parseInt(plan?.planDescription?.deviceDataLimit?.fieldValue?.toString().split(' ')?.[0], 10) ?? null),
          currency: this.fb.control(plan?.planDescription?.deviceDataLimit?.fieldValue?.toString().split(' ')?.[1] || null),
          limited: this.fb.control(!!plan?.planDescription?.dataLimit?.fieldValue || null),
        }),
        sms: this.fb.group({
          value: this.fb.control(parseInt(plan?.planDescription?.deviceSmsLimit?.fieldValue?.toString().split(' ')?.[0], 10) ?? null),
          limited: this.fb.control( !!plan?.planDescription?.smsLimit?.fieldValue || null),
        }),
      }),
      offerRenewalStrategy: this.fb.control(plan?.planDescription?.activationType?.fieldValue || null),
      linkedOffersIds: this.fb.array(plan?.offers?.map(offer => this.formIdNameGroup(offer)) ?? []),
      linkedOffers: this.fb.control(!!plan?.linkedOffersIds),
      zoneSets: this.fb.control(plan?.zoneSets ?? null),
      allowedImsiProviders: this.fb.control(plan?.allowedImsiProviders ?
        plan.allowedImsiProviders.map(provider => ({id: provider.id, name: provider.providerAlias})) : null),
    });
  }

  formIdNameGroup(offer: NameIdModel): FormGroup {
    return this.fb.group({
      id: this.fb.control(offer?.id),
      name: this.fb.control(offer?.name),
    });
  }
}
